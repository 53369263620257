// Default menu settings configurations
import { MenuSettingsService } from "./menu-settings.service";
export interface MenuItem {
  title: string;
  // condition: string;
  icon: string;
  page: string;
  isExternalLink?: boolean;
  issupportExternalLink?: boolean;
  isStarterkitExternalLink?: boolean;
  badge: { type: string, value: string };
  submenu: {
    items: Partial<MenuItem>[];
  };
  section: string;
}

export interface MenuConfig {
  horizontal_menu: {
    items: Partial<MenuItem>[]
  };
  vertical_menu: {
    items: Partial<MenuItem>[]
  };
}
// import MenuSettingsService
let MenuSettingsConfig: MenuConfig = {
  horizontal_menu: {
    items: [
      // {
      //   title: 'الرئيسية',
      //   icon: 'la-home',
      //   page: 'null',
      //   badge: { type: 'badge-info', value: '3' },
      //   submenu: {
      //     items: [
      //       {
      //         title: 'Ecommerce',
      //         icon: 'la-cart-plus',
      //         page: '/dashboard/ecommerce'
      //       }
      //     ]
      //   }
      // }
    ]
  },
  vertical_menu: {
    items: [
      // {
      //   title: 'الرئيسية',
      //   icon: 'la-home',
      //   page: '/dashboard/ecommerce',
      //   // badge: { type: 'badge-info', value: '20' }
      // },
      // {
      //   title: 'الصفحات',
      //   // condition: "ruleId === '3'",

      //   icon: 'la-book',
      //   page: 'null',
      //   // badge: { type: 'badge-info', value: '20' }, 
      //   submenu: {
      //     items: [
      //       {
      //         title: 'عرض الصفحات',
      //         page: '/dashboard/viewpages',
      //         icon: 'la-edit',
      //       },
      //       {
      //         title: 'إضافة صفحة جديدة',
      //         page: '/dashboard/addpages',
      //         icon: 'la-edit',
      //       }
      //       //   ,       
      //       // {
      //       //   title: 'تعديل',
      //       //   page: '/dashboard/editpages',
      //       //   icon: 'la-edit',
      //       // }   
      //     ]
      //   }
      // },
      // {
      //   title: 'الباقات',
      //   icon: 'feather ft-briefcase',
      //   page: 'null',
      //   // badge: { type: 'badge-info', value: '5' }, 
      //   submenu: {
      //     items: [
      //       {
      //         title: 'عرض الباقات',
      //         page: '/dashboard/viewpackages',
      //         icon: 'la-edit',
      //       },
      //       {
      //         title: 'إضافة باقة جديدة',
      //         page: '/dashboard/addpackages',
      //         icon: 'la-edit',
      //       }
      //     ]
      //   }
      // }
      // ,
      // {
      //   title: 'المستخدمين',
      //   page: '/dashboard/users',
      //   icon: 'la-user',
      // }
      // ,
      // {
      //   title: 'المستحقين',
      //   page: '/dashboard/accountsreceivable',
      //   icon: 'la-user',
      // },
      // {
      //   title: 'أرصدة المستفيدين',
      //   page: '/dashboard/beneficiaryaccounts',
      //   icon: 'la-dollar',
      // },
      // {
      //   title: 'المعاملات المالية',
      //   page: '/dashboard/financialtransaction',
      //   icon: 'la-bank',
      // },
      // {
      //   title: 'أرشيف التحويلات',
      //   page: '/dashboard/transfersArchive',
      //   icon: 'la-dollar',
      // },
      // {
      //   title: 'الإعلانات',
      //   page: '/dashboard/ads',
      //   icon: 'la-eye',
      // },
      // {
      //   title: 'محرك الصور',
      //   page: '/dashboard/slider',
      //   icon: 'la-photo',
      // },
      // {
      //   title: 'طلبات المراسلة',
      //   icon: 'la-envelope',
      //   page: 'null',
      //   // badge: { type: 'badge-info', value: '5' }, 
      //   submenu: {
      //     items: [
      //       {
      //         title: 'الرسائل الواردة',
      //         page: '/dashboard/usersmessages',
      //         icon: 'la-envelope',
      //       },
      //       {
      //         title: 'الرسائل المرسلة',
      //         page: '/dashboard/sentusersmessages',
      //         icon: 'la-envelope',
      //       }
      //     ]
      //   }
      // }
      // ,
      // {
      //   title: 'الإعدادات',
      //   icon: 'feather ft-settings',
      //   page: 'null',
      //   // badge: { type: 'badge-info', value: '10' },
      //   submenu: {
      //     items: [
      //       {
      //         title: 'عرض الإعدادات',
      //         page: '/dashboard/viewAppSettings',
      //         icon: 'la-info',
      //       },
      //       {
      //         title: 'تعديل الإعدادات',
      //         page: '/dashboard/editAppSettings',
      //         icon: 'la-edit',
      //       }
      //     ]
      //   }
      // }
    ]
  }

};
if(localStorage.getItem("ruleId") == "1"){
  MenuSettingsConfig.vertical_menu.items.unshift(
    {
      title: 'الرئيسية',
      icon: 'la-home',
      page: '/dashboard/ecommerce',
      // badge: { type: 'badge-info', value: '20' }
    },
    {
    title: 'الصفحات',
    // condition: "ruleId === '3'",
    icon: 'la-book',
    page: 'null',
    submenu: {
      items: [
        {
          title: 'عرض الصفحات',
          page: '/dashboard/viewpages',
          icon: 'la-edit',
        },
        {
          title: 'إضافة صفحة جديدة',
          page: '/dashboard/addpages',
          icon: 'la-edit',
        }
      ]
    }
  }
  ,
  {
    title: 'الباقات',
    icon: 'feather ft-briefcase',
    page: 'null',
    // badge: { type: 'badge-info', value: '5' }, 
    submenu: {
      items: [
        {
          title: 'عرض الباقات',
          page: '/dashboard/viewpackages',
          icon: 'la-edit',
        },
        {
          title: 'إضافة باقة جديدة',
          page: '/dashboard/addpackages',
          icon: 'la-edit',
        }
      ]
    }
  }
  ,
  {
    title: 'الأقسام',
    icon: 'feather ft-briefcase',
    page: 'null',
    // badge: { type: 'badge-info', value: '5' }, 
    submenu: {
      items: [
        {
          title: 'عرض الأقسام',
          page: '/dashboard/ViewCategories',
          icon: 'la-edit',
        },
        {
          title: 'إضافة إلي الأقسام',
          page: '/dashboard/addToCategories',
          icon: 'la-edit',
        }
      ]
    }
  }
  ,
  {
    title: 'المتجر',
    icon: 'feather ft-briefcase',
    page: 'null',
    // badge: { type: 'badge-info', value: '5' }, 
    submenu: {
      items: [
        {
          title: 'عرض المتجر',
          page: '/dashboard/ViewMatjer',
          icon: 'la-edit',
        },
        {
          title: 'إضافة إلي المتجر',
          page: '/dashboard/addToMatjer',
          icon: 'la-edit',
        }
      ]
    }
  }
  ,
  {
    title: 'مبيعات المتجر',
    icon: 'feather ft-briefcase',
    page: 'null',
    // badge: { type: 'badge-info', value: '5' }, 
    submenu: {
      items: [
        {
          title: 'المبيعات',
          page: '/dashboard/MatjerSales',
          icon: 'la-edit',
        },
        {
          title: 'إجمالي المبيعات',
          page: '/dashboard/MatjerSalesDetails',
          icon: 'la-edit',
        }
      ]
    }
  }
  ,
  {
    title: 'العروض',
    icon: 'feather ft-briefcase',
    page: 'null',
    // badge: { type: 'badge-info', value: '5' }, 
    submenu: {
      items: [
        {
          title: 'مبيعات العروض',
          page: '/dashboard/offers',
          icon: 'la-dollar',
        }
      ]
    }
  }
  ,
  {
    title: 'العروض السريعة',
    icon: 'feather ft-briefcase',
    page: 'null',
    // badge: { type: 'badge-info', value: '5' }, 
    submenu: {
      items: [
        {
          title: 'عرض العروض السريعة',
          page: '/dashboard/flashDealsArchive',
          icon: 'la-edit',
        },{
          title: 'إضافة إلي العروض السريعة',
          page: '/dashboard/flashDeals',
          icon: 'la-edit',
        }
      ]
    }
  }
  ,
  {
    title: 'المستخدمين',
    page: '/dashboard/users',
    icon: 'la-user',
  }
  ,
  {
    title: 'المستحقين',
    page: '/dashboard/accountsreceivable',
    icon: 'la-user',
  },
  {
    title: 'أرصدة المستفيدين',
    page: '/dashboard/beneficiaryaccounts',
    icon: 'la-dollar',
  },
  {
    title: 'المعاملات المالية',
    page: '/dashboard/financialtransaction',
    icon: 'la-bank',
  },
  {
    title: 'أرشيف التحويلات',
    page: '/dashboard/transfersArchive',
    icon: 'la-dollar',
  },
  {
    title: 'الإعلانات',
    page: '/dashboard/ads',
    icon: 'la-eye',
  },
  {
    title: 'محرك الصور',
    page: '/dashboard/slider',
    icon: 'la-photo',
  },
  {
    title: 'الرسائل الواردة',
    icon: 'la-envelope',
    page: 'null',
    // badge: { type: 'badge-info', value: '5' }, 
    submenu: {
      items: [
        {
          title: 'الدعم الفني',
          page: '/dashboard/unauthusersmessages',
          icon: 'la-envelope',
        },
        {
          title: 'طلب مراسلة',
          page: '/dashboard/usersmessages',
          icon: 'la-envelope',
        }
      ]
    }
  },
  {
    title: 'الرسائل المرسلة',
    icon: 'la-envelope',
    page: 'null',
    // badge: { type: 'badge-info', value: '5' }, 
    submenu: {
      items: [
        {
          title: 'الدعم الفني',
          page: '/dashboard/unauthsentmessages',
          icon: 'la-envelope',
        },
        {
          title: 'طلب مراسلة',
          page: '/dashboard/sentusersmessages',
          icon: 'la-envelope',
        }
      ]
    }
  }
  ,
  {
    title: 'الإعدادات',
    icon: 'feather ft-settings',
    page: 'null',
    // badge: { type: 'badge-info', value: '10' },
    submenu: {
      items: [
        // {
        //   title: 'عرض الإعدادات',
        //   page: '/dashboard/viewAppSettings',
        //   icon: 'la-info',
        // },
        {
          title: 'الإعدادات المالية',
          page: '/dashboard/editAppSettings',
          icon: 'la-edit',
        },
        // {
        //   title: 'إعدادات الدول',
        //   page: '/dashboard/editCountries',
        //   icon: 'la-edit',
        // }
      ]
    }
  }
  
  )
}
if(localStorage.getItem("ruleId") == "2"){
  MenuSettingsConfig.vertical_menu.items.unshift(
    {
      title: 'الرئيسية',
      icon: 'la-home',
      page: '/dashboard/ecommerce',
      // badge: { type: 'badge-info', value: '20' }
    },
  {
    title: 'المستخدمين',
    page: '/dashboard/users',
    icon: 'la-user',
  }
  ,
  {
    title: 'المستحقين',
    page: '/dashboard/accountsreceivable',
    icon: 'la-user',
  },
  {
    title: 'أرصدة المستفيدين',
    page: '/dashboard/beneficiaryaccounts',
    icon: 'la-dollar',
  },
  {
    title: 'المعاملات المالية',
    page: '/dashboard/financialtransaction',
    icon: 'la-bank',
  },
  {
    title: 'أرشيف التحويلات',
    page: '/dashboard/transfersArchive',
    icon: 'la-dollar',
  }
  )
}
if(localStorage.getItem("ruleId") == "3"){
  MenuSettingsConfig.vertical_menu.items.unshift(
    // {
    //   title: 'الرئيسية',
    //   icon: 'la-home',
    //   page: '/dashboard/ecommerce',
    //   // badge: { type: 'badge-info', value: '20' }
    // },
  {
    title: 'طلبات المراسلة',
    icon: 'la-envelope',
    page: 'null',
    // badge: { type: 'badge-info', value: '5' }, 
    submenu: {
      items: [
        {
          title: 'الرسائل الواردة',
          page: '/dashboard/usersmessages',
          icon: 'la-envelope',
        },
        {
          title: 'الرسائل المرسلة',
          page: '/dashboard/sentusersmessages',
          icon: 'la-envelope',
        }
      ]
    }
  }  
  )
}

export default MenuSettingsConfig
// export const Rule : any = {

//   let ruleId : any = localStorage.getItem("ruleId")
//       // console.log("**********", this.ruleId)
// }





