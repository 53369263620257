import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  isPageLoaded = false;
  userData: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService, private toastr: ToastrService
  ) {
    this.route.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'];
    });

  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      // credential: ['', Validators.compose([Validators.required, Validators.pattern(/^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/)])],
      credential: ['', Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required])],
    });
    localStorage.removeItem('currentLayoutStyle');
    this.isPageLoaded = true;
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  tryLogin() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.submitted = false;
      return;
    }
    let formdata: FormData = new FormData();
    formdata.append('credential', this.f.credential.value);
    formdata.append('password', this.f.password.value);
    // this.authService.doLogin(formdata).subscribe((res: any) => {
    //     this.authService.sendToken(res.data.Authorization.token);
    //     let returnUrl = '/dashboard/ecommerce';
    //     this.router.navigate([returnUrl]);      
    // }
    // ,(error:any)=>{
    //   this.toastr.success('بياناتك التي ادخلتها غير موجوده تحقق منها مره اخرى.');
    // }
    // );
    this.authService.doLogin(formdata).subscribe(
        (res) => {
          this.authService.sendToken(res.data.Authorization.token);
          this.authService.sendRule(res.data.user.admin_level);
          this.authService.sendUserName(res.data.user.name);
          this.authService.sendAdminId(res.data.user.id);
          this.authService.sendphotoURL(res.data.user.img);
          let returnUrl;
          if(res.data.user.admin_level =='1' || res.data.user.admin_level =='2')
          {
            returnUrl = '/dashboard/ecommerce';
            this.router.navigate([returnUrl]); 
          }else if (res.data.user.admin_level =='3'){
            returnUrl = '/dashboard/usersmessages';
            this.router.navigate([returnUrl]); 
          }
        },
        (err) => {
          this.toastr.success('بياناتك التي ادخلتها غير موجوده تحقق منها مره اخرى.');
        }
      );
  }
}
