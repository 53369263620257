<div class="app-content content mx-auto px-4 h-full" *ngIf="isPageLoaded">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
        <div class="content-header row">
        </div>
        <div class="content-body">
            <section class="row flexbox-container">
                <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-lg-4 col-md-8 col-10 p-0 my-auto">
                        <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                            <div class="card-header border-0 mt-2">
                                <div class="card-title text-center mx-auto" style="width: 150px;">
                                    <img src="../../assets/images/logo/logo.svg" width="100%" alt="branding logo">
                                </div>
                            </div>
                            <div class="card-content my-2">
                                <div class="card-body">
                                    <form class="form-horizontal" [formGroup]="loginForm" (ngSubmit)="tryLogin()">
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="text" formControlName="credential" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.credential.errors }" id="user-name"
                                                placeholder="Enter Username">
                                            <div class="form-control-position">
                                                <i class="la la-user"></i>
                                            </div>
                                            <div *ngIf="submitted && f.credential.errors" class="invalid-feedback">
                                                <div *ngIf="f.credential.errors.required">Username is required</div>
                                            </div>
                                        </fieldset>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="password" placeholder="Enter Password"
                                                formControlName="password" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                            <div class="form-control-position">
                                                <i class="la la-key"></i>
                                            </div>
                                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                <div *ngIf="f.password.errors.required">Password is required</div>
                                            </div>

                                        </fieldset>
                                        <div class="form-group row justify-content-end">
                                            <div class="col-md-6 col-12 float-sm-left text-left text-sm-left"><a
                                                    [routerLink]="['/forgetpassword']" class="card-link">نسيت كلمة المرور؟</a>
                                            </div>
                                        </div>
                                        <button type="submit" class="btn btn-success btn-block mt-2">
                                            <i class="fa fa-refresh fa-spin" *ngIf="submitted"></i>
                                            <i class="feather ft-unlock" *ngIf="!submitted"></i> تسجيل الدخول
                                        </button>
                                    </form>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </div>
</div>