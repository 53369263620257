import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  constructor(private http: HttpClient,) { }
  private baseUrl = 'https://tipappsa.com/api/admin/';
  editProfileData(options){
    let url =  `${this.baseUrl}profile/edit`;
    return this.http.post(url, null, options);
  }
  updateProfileData(body, options){
    console.log('body', body);
    let url =  `${this.baseUrl}profile/update`;
    return this.http.post(url, body, options);
  }
  forgetPass(body){
    let url =  `${this.baseUrl}password/forget-password`;
    return this.http.post(url, body);
  }
  otp(body){
    let url =  `${this.baseUrl}password/otp-password`;
    return this.http.post(url, body);
  }
  resetPass(body){
    let url =  `${this.baseUrl}password/reset-password`;
    return this.http.post(url, body);
  }
  getRules(options){
    let url =  `${this.baseUrl}rule`;
    return this.http.get(url, options);

  }

//   upload(file):Observable<any> {
//     let url =  `${this.baseUrl}profile/update`;

//     // Create form data
//     const formData = new FormData(); 
      
//     // Store form name as "file" with file data
//     formData.append("img", file, file.name);
      
//     // Make http post request over api
//     // with formData as req
//     return this.http.post(url, formData)
// }
}
