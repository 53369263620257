<div class="app-content content mx-auto px-4 h-full" *ngIf="isPageLoaded">
  <div class="content-overlay"></div>
  <div class="content-wrapper">
      <div class="content-header row">
      </div>
      <div class="content-body">
          <section class="row flexbox-container">
              <div class="col-12 d-flex align-items-center justify-content-center">
                  <div class="col-lg-4 col-md-8 col-10 p-0 my-auto">
                      <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                          <div class="card-header border-0 mt-2">
                              <div class="card-title text-center mx-auto" style="width: 150px;">
                                  <img src="../../assets/images/logo/logo.svg" width="100%" alt="branding logo">
                              </div>
                          </div>
                          <h5>لإسترجاع كلمة المرور سوف يتم إرسال رمز للتحقق علي البريد الإلكتروني أو رقم الجوال المستخدم في تسجيل الدخول</h5>
                          <div class="card-content my-1">
                              <div class="card-body">
                                  <form class="form-horizontal" [formGroup]="otpForm" (ngSubmit)="submit()">
                                    
                                    <fieldset class="form-group position-relative has-icon-left">
                                      <input type="number" placeholder="رمز التحقق"
                                          formControlName="otp" class="form-control"
                                          [ngClass]="{ 'is-invalid': submitted && f.otp.errors }" />
                                      <div class="form-control-position">
                                          <i class="la la-key"></i>
                                      </div>
                                      <div *ngIf="submitted && f.otp.errors" class="invalid-feedback">
                                          <div *ngIf="f.otp.errors.required">otp is required</div>
                                      </div>
                                  </fieldset>
                                      <button type="submit" class="btn btn-outline-info btn-block mt-2">
                                          <i class="fa fa-refresh fa-spin" *ngIf="submitted"></i>
                                          <i class="feather ft-unlock" *ngIf="!submitted"></i> إرسال
                                      </button>
                                  </form>
                              </div>
                              
                          </div>
                      </div>
                  </div>
              </div>
          </section>

      </div>
  </div>
</div>