import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { AlertService } from '../_services/alert.service';
import { UserProfileService } from '../_services/user-profile.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  public currentUser: any;
  forgetPassForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  isPageLoaded = false;
  userData: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public userService: UserProfileService,private authService: AuthService, private toastr: ToastrService
  ) {
    this.route.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'];
    });

  }

  ngOnInit() {
    this.forgetPassForm = this.formBuilder.group({
      // credential: ['', Validators.compose([Validators.required, Validators.pattern(/^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/)])],
      credential: ['', Validators.compose([Validators.required])],
    });
    this.isPageLoaded = true;
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.forgetPassForm.controls;
  }

  submit() {
    debugger;
    if (this.forgetPassForm.invalid) {
      return;
    }
     this.userService.forgetPass(this.forgetPassForm.value).subscribe((res: any) => {
      console.log('res', res);
      if (res.status == 'ok') {
        this.toastr.success('تم إرسال رقم تعريفي على الإيميل التالي.');
        this.router.navigate(['/otpverification']);
      };
    });
  }
}

