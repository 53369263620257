import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { AlertService } from '../_services/alert.service';
import { UserProfileService } from '../_services/user-profile.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-verificationforreset-password',
  templateUrl: './verificationforreset-password.component.html',
  styleUrls: ['./verificationforreset-password.component.css']
})
export class VerificationforresetPasswordComponent implements OnInit {
  otpForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  isPageLoaded = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public userService: UserProfileService,
    private toastr: ToastrService
  ) {
    this.route.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'];
    });

  }

  ngOnInit() {
    this.otpForm = this.formBuilder.group({
      otp: ['', Validators.required],
    });
    this.isPageLoaded = true;
  }

  // convenience getter for easy access to form fields
  submit() {
    debugger;
    if (this.otpForm.invalid) {
      return;
    }
     this.userService.otp(this.otpForm.value).subscribe((res: any) => {
      console.log('res', res);
      if (res.status == 'ok') {
        this.toastr.success('تم التحقق بنجاح.');
        // this.router.navigate(['/resetpassword']);
        // this.router.navigate(['/resetpassword', res.data.admin_id]);
        this.router.navigate(['/resetpassword',res.data.admin_id]);

      };
    });
  }
}
