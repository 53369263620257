import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthService {
  constructor(public afAuth: AngularFireAuth, private router :Router, private http: HttpClient,private toastr: ToastrService) {}
  apiUrl='https://tipappsa.com/api/admin/login'
  GetAll(){
    return this.http.get(this.apiUrl);
  }
  GetByCode(code: any){
    return this.http.get(this.apiUrl+'?credential:'+code);
  }
  // Facebook login
  doFacebookLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.FacebookAuthProvider();
      this.afAuth.signInWithPopup(provider).then(
        res => {
          resolve(res);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  // Github login
  doGitHubLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.GithubAuthProvider();
      this.afAuth.signInWithPopup(provider).then(
        res => {
          resolve(res);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  // Twitter login
  doTwitterLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.TwitterAuthProvider();
      this.afAuth.signInWithPopup(provider).then(
        res => {
          resolve(res);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  // Google login
  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      this.afAuth.signInWithPopup(provider).then(
        res => {
          resolve(res);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  // Register
  doRegister(value) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(value.email, value.password)
        .then(
          res => {
            resolve(res);
          },
          err => reject(err)
        );
    });
  }

   logout(): void {
   this.isUserLoggedIn = false;
      localStorage.removeItem('isUserLoggedIn'); 
   }

   
  // Login

  isUserLoggedIn: boolean = false;
  // userData:any;

  doLogin(value){
     return this.http.post<any>('https://tipappsa.com/api/admin/login', value);
    }

    sendToken(token: string) {
      localStorage.setItem("currentUser", token)
    }
    getToken() {
      return localStorage.getItem("currentUser")
    }
    sendphotoURL(img: any) {
      localStorage.setItem("photoURL", img)
    }
    getphotoURL() {
      return localStorage.getItem("photoURL")
    }
    sendUserName(name: any) {
      localStorage.setItem("userName", name)
    }
    getUserName() {
      return localStorage.getItem("userName")
    }
    sendRule(ruleId: any) {
      localStorage.setItem("ruleId", ruleId)
    }
    getRule() {
      return localStorage.getItem("ruleId")
    }
    sendAdminId(AdminId: any) {
      localStorage.setItem("adminId", AdminId)
    }
    getAdminId() {
      return localStorage.getItem("adminId")
    }
    isLoggedIn() {
      return this.getToken() !== null;
    }
    // this.isUserLoggedIn = value.email == 'admin@admin.com' && value.password == '123456';
    // if(this.isUserLoggedIn){
    //   localStorage.setItem('currentUser', "jhghfdrtstdyigh"); 
    //   this.router.navigate(['/dashboard/ecommerce']);
    // }
    // else{
    //   this.toastr.warning('فشل تسجيل الدخول.', 'حاول مره أخري.');      
    // }
//  return of(this.isUserLoggedIn).pipe(
//     delay(1000),
//     tap(val => { 
//        console.log("Is User Authentication is successful: " + val); 
//     })
//  );

  // Logout
  // doLogout() {
  //   return new Promise<void>((resolve, reject) => {
  //     if (firebase.auth().currentUser) {
  //       localStorage.removeItem('currentUser');
  //       localStorage.removeItem('remember');
  //       this.afAuth.signOut();
  //       resolve();
  //     } else {
  //       localStorage.removeItem('currentUser');
  //       resolve();
  //     }
  //   });
  // }
  doLogout(): void {
    this.isUserLoggedIn = false;
       localStorage.removeItem('currentUser'); 
    }
}
