
  import { Component, OnInit, Renderer2 } from '@angular/core';
  import { Router, ActivatedRoute } from '@angular/router';
  import { FormBuilder, FormGroup, Validators } from '@angular/forms';
  import { AuthService } from '../_services/auth.service';
import { ToastrService } from 'ngx-toastr';
  import { AlertService } from '../_services/alert.service';
import { UserProfileService } from '../_services/user-profile.service';
  
  @Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
  })
  export class ResetPasswordComponent implements OnInit {
    public currentUser: any;
    resetForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    isPageLoaded = false;
    userData: any;
    e: any;
    constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      public userService: UserProfileService,private authService: AuthService, private toastr: ToastrService
    ) {
      this.route.queryParams.subscribe(params => {
        this.returnUrl = params['returnUrl'];
      });
  
    }
  
    ngOnInit() {
      this.resetForm = this.formBuilder.group({
        password: ['', Validators.compose([Validators.required])],
        password_confirmation: ['', Validators.compose([Validators.required])],
      });
      this.isPageLoaded = true;
      this.route.paramMap.subscribe(params => {
        this.e = this.route.snapshot.paramMap.get("id");
    });
    }
  
    // convenience getter for easy access to form fields
    get f() {
      return this.resetForm.controls;
    }
  
    submit() {
      if (this.resetForm.invalid) {
        return;
      }
      let formdata: FormData = new FormData();
    formdata.append('user_id', this.e);
    formdata.append('password', this.f.password.value);
    formdata.append('password_confirmation', this.f.password_confirmation.value);
      this.userService.resetPass(formdata).subscribe((res: any) => {
        console.log('res', res);
        if (res.status == 'ok') {
          this.toastr.success('تم التأكد بنجاح.');
          this.router.navigate(['/login']);
  
        };
        console.log(this.resetForm.value);
  
      });
    }
  }
  
